import React from "react";
import logo from "./../images/intro_logo.svg";
import "animate.css";

export default function Intro() {
  return (
    <div id="intro" className="intro-bg hidden">
      <img
        src={logo}
        alt="intro logo mapa dzwiekowa"
        className="intro-logo"
      />
    </div>
  );
}
