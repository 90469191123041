import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  places: [],
  activePlaceId: undefined,
};

export const placesSlice = createSlice({
  name: 'places',
  initialState,
  reducers: {
    addPlace: (state, action) => {
      state.places.push(action.payload);
    },
    setPlaces: (state, action) => {
      state.places = action.payload;
    },
    setActivePlaceId: (state, action) => {
      state.activePlaceId = action.payload;
    },
  },
});

export const { addPlace, setPlaces, setActivePlaceId } = placesSlice.actions;

export const selectPlaces = (state) => state.places.places;
export const selectPlace = (state, id) => state.places.places?.find(place => place.id === id);
export const selectActivePlace = (state) => state.places.places?.find(place => place.id === state.activePlaceId);
export const selectActivePlaceId = (state) => state.places.activePlaceId;

export default placesSlice.reducer;
