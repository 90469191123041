import { Container, Row, Col } from 'react-bootstrap';
import { BrowserRouter as Router} from 'react-router-dom';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import Content from './components/Content';
import Intro from './components/Intro';
import { useDispatch } from 'react-redux';
import { setActivePlaceId, setPlaces } from './reducers/places';
import { useEffect } from 'react';

function App() {
  const dispatch = useDispatch();
  dispatch(setActivePlaceId(0));

  const displayIntro = () => {
    const intro = document.querySelector('#intro');
    intro.classList.remove('hidden');
    setTimeout(() => {
      intro.classList.add('hidden');
    }
    , 4000);
  }

  useEffect(() => {
    displayIntro();
    fetch('https://dzwiekowamapabialegostokuapi.pb.edu.pl/api/v1/sound_map_data')
      .then(response => {
        return response.json();
      })
      .then(data => {
        dispatch(setPlaces(data));
      })

  }, [])

  return (
    <Router>
      <Container id="AppContainer" className="m-0 p-0 vh-100 vw-100" fluid>
        <Row className="m-0 h-100">
          <Col xs={8} className="p-0 d-flex bg-primary h-100">
            <div className="d-flex flex-row flex-fill">
              <div className="v-spacer z-60 bg-primary">
              </div>
              <div className="d-flex flex-column flex-fill">
                <div className="h-spacer d-flex flex-row-reverse">
                  <div className="sqr-spacer bg-white"></div>
                  <div className="d-flex justify-content-start w-100 z-60 bg-primary">
                    <Navbar />
                  </div>
                </div>
                <Content />
                <div className="h-spacer d-flex flex-row-reverse">
                  <div className="sqr-spacer bg-white"></div>
                  <div className="d-flex justify-content-start w-100 z-60 bg-primary"><Footer /></div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={4} className="p-0 d-flex bg-white h-100" id="sidepanel">
            <div className="d-flex flex-row flex-fill w-100">
              <div className="v-spacer bg-white"></div>
              <div className="d-flex flex-column flex-fill w-100">
                <Sidebar />
              </div>
              <div className="v-spacer bg-white"></div>
            </div>
          </Col>
        </Row>
      </Container >
      <Intro/>
    </Router>
  );
}

export default App;
