import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setActivePlaceId } from '../reducers/places';
import { MapContainer, TileLayer, useMap } from '@monsonjeremy/react-leaflet';
import L from 'leaflet';
import 'leaflet.markercluster';
import { useSelector } from 'react-redux';
import { selectPlaces } from '../reducers/places';

export default function MapPanel() {
  const dispatch = useDispatch();
  const places = useSelector(selectPlaces);

  function markerClick(e) {
    dispatch(setActivePlaceId(e.target.id));
    console.log(e.target.id);
  }

  function MarkerCluster({ locations }) {
    const map = useMap();
    const markerCluster = L.markerClusterGroup();

    locations.forEach(i => {
      if (i.latitude !== undefined && i.longitude !== undefined) {
        const icon = L.icon({
          iconUrl: i.iconUrl,
          iconSize: i.iconSize,
          iconAnchor: i.iconAnchor,
        });
        const marker = L.marker([i.latitude, i.longitude], { icon: icon });
        marker.id = i.id;
        marker.on('click', markerClick);
        markerCluster.addLayer(marker);
      }
    })
    map.addLayer(markerCluster);
    return null;
  }

  useEffect(() => {
    dispatch(setActivePlaceId("default"));
  });

  return (
    <div className="w-100 h-100 shadow-hard" id="map">
      <MapContainer center={[53.128234, 23.156327]} zoom={13} maxZoom={18} scrollWheelZoom={true} className="w-100 h-100">
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
        />
        <MarkerCluster locations={places} />
      </MapContainer>
    </div>
  )
}
