import React from 'react'
import PlaceInfoSection from './PlaceInfoSection';
import { useSelector } from 'react-redux';
import { selectPlace, selectActivePlaceId } from '../reducers/places';

export default function ContractPanel() {
  const id = 'contact';
  const place = useSelector((state) => selectPlace(state, id));
  const sections = place?.sections?.map((item, index) => {
    return <PlaceInfoSection key={index} {...item} />
  });
  return (
    <div>
      <div className="h-spacer"></div>
      <div className="h-spacer"></div>
      <div>
        {/* <h5 className="h-line">Kontakt</h5> */}
        <div className="panel-text">{sections}</div>
      </div>
      <div className="h-spacer"></div>
    </div>
  );
}
