import React from "react";
import PlaceInfoSection from './PlaceInfoSection';
import { useSelector } from 'react-redux';
import { selectPlace, selectActivePlaceId } from '../reducers/places';

export default function AboutPanel(panels_data) {
  const id = 'about_project';
  const place = useSelector((state) => selectPlace(state, id));
  const sections = place?.sections?.map((item, index) => {
    return <PlaceInfoSection key={index} {...item} />
  });
  return (
    <div>
      <div className="h-spacer"></div>
      <div className="h-spacer"></div>
      <div>
        {/* // About project */}
        <div className="panel-text">{sections}</div>
      </div>
      <div className="h-spacer"></div>
    </div>
  );
}
