import React from 'react'

export default function Footer() {
  return (
    <div className="d-flex bg-primary small text-light align-items-center align-middle">
      <div className="rectangle mr-2"></div>
      <p className="mb-0">COPYRIGHT © 2023 MAPA DŹWIĘKOWA BIAŁEGOSTOKU. ALL RIGHTS RESERVED.</p>
    </div>
  )
}
