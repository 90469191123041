import React from 'react'
import { Image } from 'react-bootstrap';
import PlaceInfoSection from './PlaceInfoSection';
import { useSelector } from 'react-redux';
import { selectPlace, selectActivePlaceId } from '../reducers/places';

export default function PlaceInfoPanel(props) {
  const id = useSelector(selectActivePlaceId);
  const place = useSelector((state) => selectPlace(state, id));
  const sections = place?.sections?.map((item, index) => {
    return <PlaceInfoSection key={index} {...item} />
  });
  return (
    <div>
      <div className="h-spacer"></div>
      {place?.extraSpacing && <div className="h-spacer"></div>}
      {place?.thumbnail && <Image className="mb-5 place-picture" src={place.thumbnail} />}
      {sections}
      <div className="h-spacer"></div>
    </div>
  );
}
