import React, { useState } from 'react'
import { Image } from 'react-bootstrap';
import CarouselModal from './CarouselModal';

export default function PlaceInfoSection(props) {
  const [index, setIndex] = useState(0);
  const [show, setShow] = useState(props.show);

  const handlePictureClick = (e) => {
    console.log(e);
    setIndex(e.target.id);
    setShow(true);
  }

  const stopOtherAudio = (e) => {
    const audios = document.getElementsByTagName('audio');
    for (let i = 0; i < audios.length; i++) {
      if (audios[i] !== e.target) {
        audios[i].pause();
        audios[i].currentTime = 0;
      }
    }
  }

  const sounds = props.sounds?.map((item, index) => {
    return (
      <li key={index} className="collapsible-hover-area">
        <div>
          <span className="material-icons-outlined rotating-icon">play_arrow</span>
          <h6>{item.header}</h6>
          <p className="ml-1">{item.text}</p>
        </div>
        <div className="collapsible ml-4" id="collapseExample">
          {item.details?.map((detail, index) => {
            return (
              <div className="d-block" key={index}>
                <h6 className="d-inline" style={{fontSize: '0.875rem'}}>{detail.displayName}: </h6>
                <p className="ml-1 d-inline" style={{fontSize: '0.875rem'}}>{detail.content}</p>
              </div>
            );
          })}
          <audio controls controlsList="nodownload" src={item.src} className="mt-2 mb-2" onPlay={stopOtherAudio} />
        </div>
      </li>);
  });

  const pictures = props.pictures?.map((item, index) => {
    return <Image className="thumbnail" src={item.url} alt="" key={index} id={index} onClick={handlePictureClick} />;
  });

  return (
    <div>
      {props.header && <h5 className="h-line">{props.header}</h5>}
      {props.text &&
        props.text.length > 0 &&
        props.text.map((paragraph, index) =>
        <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
        )}
      {sounds && <ul className="inline-ul">{sounds}</ul>}
      <div>
        <div className="d-flex flex-wrap justify-content-center">
          {pictures}
          {pictures && <CarouselModal show={show} index={index} setShow={setShow} setIndex={setIndex} pictures={props.pictures} />}
        </div>
      </div>
    </div>
  );
}
