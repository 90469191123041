import React from "react";

export default function DownloadPanel() {
  return (
    <div>
      <div className="h-spacer"></div>
      <div className="h-spacer"></div>
      <div>
        <h5 className="h-line">Do Pobrania</h5>
        <p className="panel-text">{"TODO"}</p>
      </div>
      <div className="h-spacer"></div>
    </div>
  );
}
