import React from 'react'
import { Modal, Carousel } from 'react-bootstrap'

export default function CarouselModal(props) {

  const handleSelect = (selectedIndex, e) => {
    props.setIndex(selectedIndex);
  };

  const carouselItems = props.pictures.map((item, index) => {
    return (
      <Carousel.Item key={index}>
        <img
          src={item.url}
          alt="slide alt"
        />
      </Carousel.Item>);
  });

  return (
    <Modal
      show={props.show}
      onHide={() => props.setShow(false)}
      dialogClassName="carousel-modal-dialog"
      fullscreen={'true'}
      animation={false}
    >
      <Modal.Body>
        <Carousel variant="dark" activeIndex={parseInt(props.index)} onSelect={handleSelect} slide={false} fade={false} interval={null}>
          {carouselItems}
        </Carousel>
      </Modal.Body>
    </Modal>
  )
}
