import React from 'react'
import PlaceInfoPanel from './PlaceInfoPanel';
import DownloadPanel from './DownloadPanel';
import AboutPanel from './AboutPanel';
import ContractPanel from './ContractPanel';
import { Switch, Route } from 'react-router-dom';

export default function Sidebar() {
  return (
    <div className="flex-fill">
      <Switch>
        <Route path="/" exact>
          <PlaceInfoPanel />
        </Route>
        <Route path="/download" exact>
          <DownloadPanel />
        </Route>
        <Route path="/about" exact>
          <AboutPanel />
        </Route>
        <Route path="/contact" exact>
          <ContractPanel />
        </Route>
      </Switch>
    </div>
  )
}
