import React from 'react'
import { Navbar as BSNavbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import InstagramLogo from './../images/ig.svg'
import FacebookLogo from './../images/fb.svg'
import LogoM from './../images/logo_m.svg'
import PBSygnet from './../images/sygnet_pb.svg'

export default function Navbar() {
  return (
    <div className="w-100">
      <BSNavbar bg="primary" variant="dark" className="pl-0">
        <Container className="justify-content-center align-items-center">
          <a href="https://dzwiekowamapabialegostoku.pb.edu.pl/" target="_blank">
            <div className="w5rem mr5">
              <img src={LogoM} alt="Logo PB" className='logo_pb block' />
            </div>
          </a>
          <Nav>
            <NavLink to="/" className="nav-sep nav-link" activeClassName="active" exact>Mapa</NavLink>
            <NavLink to="/download" className="nav-sep nav-link d-none" activeClassName="active" exact>Do pobrania</NavLink>
            <NavLink to="/about" className="nav-sep nav-link" activeClassName="active" exact>O projekcie</NavLink>
            <NavLink to="/contact" className="nav-sep nav-link" activeClassName="active" exact>Kontakt</NavLink>
          </Nav>
          <div className='text-white ml-auto my-auto mr-5'>
            <a href="https://www.facebook.com/profile.php?id=100090417565559" target="_blank">
              <img src={FacebookLogo} alt="Facebook" className='social_media_icon' />
            </a>
            <a href="https://www.instagram.com/dzwiekowamapabialegostoku/?igshid=YmMyMTA2M2Y%3D&fbclid=IwAR1HudDeh3Ba9tcoOD-72nj8jpDW4LxhyQqbmE-g948f8OkHKt36lhg_3Fc" target="_blank">
              <img src={InstagramLogo} alt="Instagram" className='social_media_icon ml-2' />
            </a>
            <a href="https://www.pb.edu.pl" target="_blank">
              <img src={PBSygnet} alt="Politechnika Białostocka" className='social_media_icon ml-5' />
            </a>
          </div>
        </Container>
      </BSNavbar>
    </div>
  );
}
