import React from 'react';
import MapPanel from './MapPanel';
import GalleryPanel from './GalleryPanel';
import { Switch, Route } from 'react-router-dom';

export default function Content() {
  return (
    <div className="flex-fill h-100">
      <Switch>
        <Route path="/" exact>
          <MapPanel />
        </Route>
        <Route path={["/about", "/contact", "/download"]} exact>
          <GalleryPanel />
        </Route>
      </Switch>
    </div>
  )
}
