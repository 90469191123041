import React from "react";
import image1 from "../images/gallery_image1.jpg";
import ImageGallery from "react-image-gallery";
const images = [
  {
    original:
      "https://raw.githubusercontent.com/Dzwieki-Miasta-Bialystok/SoundMapGallery/master/zdj%C4%99cia/MBHI-0184.jpg",
  },
  {
    original:
      "https://raw.githubusercontent.com/Dzwieki-Miasta-Bialystok/SoundMapGallery/master/zdj%C4%99cia/MBHI-1114.jpg",
  },
  {
    original:
      "https://raw.githubusercontent.com/Dzwieki-Miasta-Bialystok/SoundMapGallery/master/zdj%C4%99cia/MBHI-11926.jpg",
  }
  ,
  {
    original:
      "https://raw.githubusercontent.com/Dzwieki-Miasta-Bialystok/SoundMapGallery/master/zdj%C4%99cia/MBHI-11927.jpg",
  },
  {
    original:
      "https://raw.githubusercontent.com/Dzwieki-Miasta-Bialystok/SoundMapGallery/master/zdj%C4%99cia/MBHI-13040.jpg",
  },
  {
    original:
      "https://raw.githubusercontent.com/Dzwieki-Miasta-Bialystok/SoundMapGallery/master/zdj%C4%99cia/MBHI-17474.jpg",
  },
  {
    original:
      "https://raw.githubusercontent.com/Dzwieki-Miasta-Bialystok/SoundMapGallery/master/zdj%C4%99cia/MBHI-6000.jpg",
  },
  {
    original:
      "https://raw.githubusercontent.com/Dzwieki-Miasta-Bialystok/SoundMapGallery/master/zdj%C4%99cia/MBHI-9875.jpg",
  },
];

export default function GalleryPanel() {
  return (
    <div className="w-100 h-100 shadow-hard position-relative z-index-50">
      <ImageGallery
        items={images}
        showThumbnails={false}
        autoPlay={true}
        showPlayButton={false}
        showNav={false}
        showBullets={true}
        showFullscreenButton={false}
      />
    </div>
  );
}
